import React from 'react';
import { Fade } from 'react-reveal';

import loadingImg from '../../../assets/Loading_logo.svg';
import styles from './Loading.module.css';

const Loading = ({ backgroundColor = '240, 248, 255' }) => {
  return (
    <Fade duration={500}>
      <div className={`${styles.Loading} Modal`} style={{ backgroundColor: `rgba(${backgroundColor}, 0.3)` }}>
        <img
          src={loadingImg}
          alt='Loading'
          // height='100svh'
          // width='100vw'
          // style={{ width: '100vw', height: '100svh' }}
          height='100px'
          width='100px'
          style={{ width: '100px', height: '100px' }}
        />
      </div>
    </Fade>
  );
};

export default Loading;
